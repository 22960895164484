/* mainWindow.css */
.mainWindow {
    height: 100vh; 
    display: flex; 
    justify-content: center; 
}

.mainWindow_container {
    height: calc(100vh - 80px);
    overflow: scroll;
    max-width: 393px; 
    width: 100%; 
    margin: 0 auto; 
    padding: 0 16px; 
    color: #fff; 
}
