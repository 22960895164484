@font-face {
    font-family: 'Helvetica'; 
    src: url('../fonts/Helvetica.woff2') format('woff2');
    font-weight: 300,400,500,600,700,800;
}

.special-for-me {  background: -webkit-gradient(linear, left top, left bottom, from(#000000), to(#15142a));
    background: -o-linear-gradient(top, #000000 0%, #15142a 100%);
    background: linear-gradient(180deg, #000000 0%, #15142a 100%);}

.customFileLabel {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    background: linear-gradient(90deg, #5376FF 0%, #324799 100%);
    color: white;
    font-size: 14px;
    border-radius: 14px;
    cursor: pointer;
    text-align: center;
    width: 359px;
    height: 54px;
}

.fileInput {
    display: none; /* Скрывает стандартное поле файла */
}