@import "../styles/global.css";

.DropdownInner {
    font-family: 'Helvetica'; 
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.dropdown-block {
    width: 360px;
    background-color: #1e1e2d;
    border-radius: 8px;
    color: white;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;
    background-color: #000;
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.15);
    width: 100%;
    height: 52px;
    box-shadow: 0px 4px 4px 0px #00000040;
    position: relative;
    z-index: 1;
}

.dropdown-header.open {
    color: #fff;
}

.block-number {
    color: white;
    padding: 5px 10px;
    font-size: 24px;
    font-weight: 400;
    line-height: 18px;
}

.block-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.dropdown-content {
    color: #cfcfcf;
    background: linear-gradient(90deg, #313131 0%, #000000 100%);
    margin-top: -10px;
    padding-top: 15px;
    border-radius: 0 0 8px 8px;
    position: relative;
    z-index: 0;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.linear {
    background: linear-gradient(180deg, #313131 0%, #181722 100%);
}

.solid {
    background: #313131;
}

.PayButton {
    width: 145px;
    height: 30px;
    border-radius: 25px;
    background-color: #1a1a1a;
    color: #fff;
    border: 1px solid #fff;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 18px;
}

.toggle-button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0 10px;
}

.toggle-button img {
    width: 20px;
    height: auto;
}

.neuroAudioText {
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
}

.progresbar {
    margin-top: -1px;
}

input[type="range"] {
    -webkit-appearance: none;
    width: 310px;
    height: 5px;
    margin: 0;
    border-radius: 5px;
    margin-bottom: 10px;
}

input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(to right, #FFCB33 0%, #FFCB33 var(--value), #ffffff var(--value), #ffffff 100%);
    height: 5px;
    border-radius: 5px;
}

input[type="range"]::-moz-range-track {
    background: linear-gradient(to right, #FFCB33 0%, #FFCB33 var(--value), #ffffff var(--value), #ffffff 100%);
    height: 5px;
    border-radius: 5px;
}

input[type="range"]::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type="range"]::-ms-fill-lower {
    background: #FFCB33;
}

input[type="range"]::-ms-fill-upper {
    background: #ffffff;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #FFCB33;
    cursor: pointer;
    margin-top: -2.5px; 
}

input[type="range"]::-moz-range-thumb {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #FFCB33;
    cursor: pointer;
}

input[type="range"]::-ms-thumb {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #FFCB33;
    cursor: pointer;
}
