/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import "../styles/global.css";
.homeInner{
    padding-top:15px;
    font-family: 'Helvetica'; 
    display:-webkit-box; 
    display:-ms-flexbox; 
    display:flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
}
.nav{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    gap:16px;
}
.row{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    gap:17px;
}
.rowItem{
    position: relative;
    text-align: center;
    color: #fff;
    background: none;
    border: none;
    padding:0;
}
.rowItem_up-Image{
    width: 110px;
    height: 140px;
}
.rowItem_down-Imag{
    width: 171px;
    height: 134px;
}
.systemButton{
    margin-top:19px;
    width: 359px;
    height: 58px;
}
.systemButton_Inner{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height:100%;
    background-color:#000;
    border-radius:10px;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
    padding: 0 10px;
}
.systemText{
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
}
.user{
    margin-top: 18px;
}
.userInner{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    height:100%;
}
.columns{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    gap:16px;
}
.rightColumns{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
}
.levelsButton{
    width: 171px;
    height: 117px;
}
.newsButton{
    width: 171px;
    height:58px;
}
.newsButtonInner{
    height: 100%;
    padding:0 10px;
    border-radius:10px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #000;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.newsText{
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
}
.fire{
    position:absolute;
    width:99px;
    left: 36px;
    top: -39px;
}
.levelsButton{
    width: 171px;
    height: 117px;
    margin-top:52px;
}
.levelsButton_Inner{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    position:relative;
    background: -webkit-gradient(linear, left top, left bottom, from(#000000), to(#212031));
    background: -o-linear-gradient(top, #000000 0%, #212031 100%);
    background: linear-gradient(180deg, #000000 0%, #212031 100%);
    border-radius: 10px;
    height:100%;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: end;
}
.levelsButtonText{
    margin-left:17px;
    margin-bottom:17px;
}
.leftColumns{
    width: 172px;
    height: 261px;
}
.leftColumnsInner{
    background: -webkit-gradient(linear, left top, left bottom, from(#000000), to(#212031));
    background: -o-linear-gradient(top, #000000 0%, #212031 100%);
    background: linear-gradient(180deg, #000000 0%, #212031 100%);
    height:100%;
    border-radius:10px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
}
.userInfo{
    margin-top:10px;
    width: 100%;
}
.logoInner{
    width:31px;
    height:31px;
    background: #1B1B1B;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius:10px;
}
.logoPhoto{
    width:25px;
    border-radius:2px;
}
.nickname{
    width: 116px;
    height: 31px;
    background: -webkit-gradient(linear, left top, right top, from(#1B1B1B), to(#060608));
    background: -o-linear-gradient(left, #1B1B1B 0%, #060608 100%);
    background: linear-gradient(90deg, #1B1B1B 0%, #060608 100%);
    border-radius: 5px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
}
.logo{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    gap:5px;
}
.nicknameText{
    font-size: 8px;
    font-weight: 400;
    line-height: 10px;
    margin-left:7px;
}
.invite{
    margin-top:6px;
    width: 152px;
    height: 47px;
    background: -webkit-gradient(linear, left top, right top, from(#FFCB33), to(#040404));
    background: -o-linear-gradient(left, #FFCB33 0%, #040404 100%);
    background: linear-gradient(90deg, #FFCB33 0%, #040404 100%);
    border-radius: 5px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
}
.inviteInfo{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
    margin: 0 10px;
    margin-top:7px;
}
.inviteTitleText{
    font-size: 10px;
    font-weight: 700;
    line-height: 10px;
    color: #000000;
    margin-top:10px;
    margin-left:10px;
}
.count{
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
}
.id{
    font-size: 8px;
    font-weight: 400;
    line-height: 10px;
}
.idNumber{
    font-size: 8px;
    font-weight: 300;
    line-height: 10px;
}
.team{
    margin-top:6px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    width: 152px;
    height: 145px;
    background: -webkit-gradient(linear, left top, left bottom, from(#1B1B1B), to(#212031));
    background: -o-linear-gradient(top, #1B1B1B 0%, #212031 100%);
    background: linear-gradient(180deg, #1B1B1B 0%, #212031 100%);
    border-radius:5px;
}
.teamItem{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
}
.teamItemText{
    font-size: 8px;
    font-weight: 400;
    line-height: 10px;
}
.teamItemForm{
    width: 74px;
    height: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-radius:3px;
}
.teamItemFormText{
    font-size: 6px;
    font-weight: 400;
    line-height: 10px;
    color:#000;
    margin-left:4px;
}
.teamItemFormNumber{
    font-size: 8px;
    color:#000;
    font-weight: 400;
    line-height: 10px;
    margin-right:4px;
}
.color1{
    background: #FFCB33;
}
.color2{
    background: #FFD559;
}
.color3{
    background: #FFE38B;
}
.color4{
    background: #FFEFBC;
}
.color5{
    background: #FFF6D9;
}
.color6{
    background: #FFF6D9;
}
.teamTitle{
    width:113px;
    font-size: 11px;
    font-weight: 400;
    line-height: 10px;
    margin-left:20px;
    margin-top:8px;
}
.teamItems{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    margin-top:13px;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
}
.teamItem{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:28px;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    margin-bottom:4px;
}

.footer{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between;
    margin-top:30px;
}
.footerText{
    width:147px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}
.fatText{
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
}
.footerTitle{
font-size: 16px;
font-weight: 700;
line-height: 18px;
}
.rowItem {
    position: relative;
}

.imageContainer {
    position: relative;
    width: 106px; /* ширина контейнера */
}

.rowItem_up-Image {
    width: 100%; /* изображение адаптируется к ширине контейнера */
}

.textOverlay {
    position: absolute;
    bottom: 6px; /* Перемещает блок с текстом вниз */
    left: 50%;
    transform: translateX(-50%); /* Смещение по горизонтали для центрирования */
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(4px); /* эффект размытия */
    background-color: rgba(0, 0, 0, 0.3); /* полупрозрачный фон */
    width: 103px;
    height: 18px;
    border-radius: 10px;
    z-index: 1;
}
.imageText1 {
    color: #fff;
    text-align: center;
    letter-spacing: 1px;
    font-size: 9px;
    font-weight: 700;
    line-height: 18px;
}
.imageText2 {
    color: #fff;
    text-align: center;
    letter-spacing: 1px;
    font-size: 9px;
    font-weight: 700;
    line-height: 18px;
}
