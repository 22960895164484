@import "../styles/global.css";

.paymentInner{

    font-family: 'Helvetica'; 
    display:flex;
    flex-direction: column;
    align-items:center
}
.paymentTitle{
    margin-top:39px;
}
.paymentTitleText{
    font-size: 32px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    
}
.paymentSubtitle{
    width:309px;
    margin-top:28px;
    text-align:center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}
.paymentSubtitleBold{
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
}
.paymentLots{
    display:flex;
    flex-direction:column; 
    margin-top:33px;   
    gap:12px;
}
.paymentItem{
    width: 359px;
    height: 52px;
    display:flex;
    flex-direction:row;
    align-items:center;
    border-radius: 10px;
    background: linear-gradient(90deg, #212031 0%, #000000 100%);
    box-shadow: 0px 4px 4px 0px #00000040;
    justify-content: space-between;
    border: none;
    color: #fff;
}
.paymentLot{
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
    margin-left: 10px;
}
.paymentCoast{
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    color: #FFEFBC;
    margin-right:10px;
}
.paymentLotText{
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
}