.menu {
    z-index: 1;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 80px;
    padding: 12px 14px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background: linear-gradient(90deg, #212031 0%, #000000 100%);

    backdrop-filter: blur(7.5px);
}

.menu-item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    padding: 10px;
    border-radius: 14px;

    svg {
        width: 24px;
        height: 24px;
    }
}

.item-black {
    background: #FFFFFF26;
}

.item-white {
    background: #FFFFFF;
}

.item-blue {
    background: linear-gradient(180deg, #5376FF 0%, #324799 100%);
}